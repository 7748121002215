<template>
    <div>
        <topBarY />
        <div class="bg">
            <img src="../../../../assets/common/pic/bg.jpg" alt="">
        </div>
        <div class="switchBar">
            <div class="tab cabinet" :class="{ active: switchIndex == 1 }">智能云网关</div>
        </div>
        <div class="line"></div>
        <div class="switchContiner">
            <div class="continer cabinetContiner">
                <div class="continer">
                    <div class="introduce">
                        <span class="tit">
                            智能云网关
                        </span>
                        <br>
                        <br>
                        <span class="txt">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智能云网关ICG1000W-50是一款基于TCP/IP网络的视频监控前端接入设<br>
                            备，可实现第三方IPC、DVR、NVR等设备视频无缝接入，实现在监控平台客户<br>
                            端对前端音视频通道的实时调度和云台控制，支持录像查询、回放和下载功能<br>
                            可提供扩展的232等硬件接口，实现对前端物联网设备的数据接入。
                        </span>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <span class="tit">
                            产品特点
                        </span>
                        <br>
                        <br>
                        <span class="txt">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;多种工作模式：支持接入点（自动WDS）、智能快速移动漫游等工作模式。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;高效的私有无线传输协议：私有无线传输协议，通过有序的轮询每个终端，消除了标准WIFI协议的无线碰撞，<br>
                            提供最佳的多用户接入速率和更低的延时。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持智能切换接入模式：可配合宁讯无线控制器，进行AP大面积覆盖。控制器漫游机制优化配置也可支持第三<br>
                            方移动客户端漫游连接。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;移动快速切换：搭配优化智能算法的车载移动单元可搭建支持移动快速切换漫游的无线覆盖网络。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;丰富的内置实用工具：采用HTML5设计，内置天线校准，站点扫描，延迟重启，Ping，路由追踪，频谱分析等<br>
                            实用的安装辅助工具，为场地勘察和链路开通提供科学分析。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;选配内置或外置天线：根据不同应用场景选配不同增益的内置或外置天线、全向或定向天线，达到最佳使用效<br>
                            果。<br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;多种供电模式：外接12-24 V直流供电，支持以太网接口供电。
                        </span>
                    </div>
                    <img src="../../../../assets/support/intelligentCloudGateway.png" alt="">
                </div>
            </div>
        </div>
        <baseBar />
    </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
    name: 'Product',
    components: {
        topBarY,
        baseBar
    },
    data() {
        return {
            switchIndex: 1
        }
    },
    methods: {
        switchClick(index) {
            this.switchIndex = index
        }
    },
    created() {
        this.$nextTick(() => {
            // 1.禁用右键菜单
            document.oncontextmenu = new Function("event.returnValue=false");
            // 2.禁用鼠标选中
            document.onselectstart = new Function("event.returnValue=false");
            // 3.禁止键盘F12键
            document.addEventListener("keydown", function (e) {
                if (e.key == "F12") {
                    e.preventDefault(); // 如果按下键F12,阻止事件
                }
            });
        });
    }
}
</script>

<style scoped>
.bg {
    position: relative;
    width: 1920px;
    height: 480px;
    overflow: hidden;
}

.bg img {
    position: absolute;
    top: -100px;
    width: 1920px;
}

.switchBar {
    width: 1920px;
    height: 70px;
    background-color: #fff;
    text-align: center;
}

.switchBar .tab {
    display: inline-block;
    margin-right: 30px;
    margin-top: 15px;
    cursor: pointer;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    background-color: #c3a35d;
    color: #f5f5f5;
    font-family: 'Microsoft Yahei';
    font-size: 16px;
    line-height: 40px;
}

.line {
    margin: 0 auto;
    width: 1400px;
    height: 1px;
    background-color: #c3a35d;
}

.switchContiner {
    width: 1920px;
    padding: 20px;
    background-color: #f7f7f7;
}

/* 智能保管柜 */
.switchContiner .cabinetContiner {
    margin: 0 auto;
    width: 1400px;
    height: 800px;
    border-radius: 5px;
    background-color: #fff;
}

.switchContiner .cabinetContiner .continer {
    position: relative;
    margin: 0 auto;
    padding-top: 40px;
    width: 1000px;
    height: 200px;
}

.switchContiner .cabinetContiner .continer .tit {
    font-size: 18px;
    margin-bottom: 20px;
}

.switchContiner .cabinetContiner .continer .txt {
    color: #999;
    font-size: 14px;
    line-height: 30px;
}

.switchContiner .cabinetContiner img {
    position: absolute;
    top: 70px;
    right: 80px;
    width: 220px;
}
</style>
